import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import {
    auth,
    nextFactory
} from './middlewares'

import Components from '../views/Components.vue'
import Error404 from '../views/Err404.vue'
import Home from '../views/Home.vue'
import Works from '../views/Works.vue'

// auth
import Signin from '../views/Signin.vue'
import PasswordRecovery from '../views/PasswordRecovery.vue'
import PasswordReset from '../views/PasswordReset.vue'
import Me from '../views/Me.vue'
import Search from '../views/Search.vue'
import WorkEdit from '../views/WorkEdit.vue'
import Work from '../views/Work.vue'

const routes = [
    {
        path: '/ui/components',
        name: 'ui_components',
        component: Components
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/errors/404'
    },
    {
        path: '/errors/404',
        name: 'error_404',
        component: Error404
    },
    {
        path: '/',
        name: 'index',
        component: Home,
        alias: ['/home', '/index'],
        meta: {
            middlewares: [auth]
        }
    },
    // ------------------------------------------------
    {
        path: '/signin',
        name: 'signin',
        component: Signin,
        alias: ['/login', '/log'],
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/password/recovery',
        name: 'password_recovery',
        component: PasswordRecovery,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/password/reset',
        name: 'password_reset',
        component: PasswordReset,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/me',
        name: 'me',
        component: Me,
        meta: {
            middlewares: [auth]
        }
    },
    // ------------------------------------------------
    {
        path: '/search',
        name: 'search',
        component: Search,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/works',
        name: 'works',
        component: Works,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/works/:id',
        name: 'work',
        component: Work,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/works/:id/edit',
        name: 'work_edit',
        component: WorkEdit,
        meta: {
            middlewares: [auth]
        }
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 }
    }
})

router.beforeEach((to, from, next) => {
    console.log('> router.beforeEach', !!store.state.access, to.path, to.name)

    store.commit('set_page_enter', to.name)
    store.commit('set_page_leave', from && from.name ? from.name : null)

    if (to.name !== 'signin') {
        sessionStorage.setItem('fp-laci-webapp_last_page', to.fullPath)
    }

    if (to.meta.middlewares) {
        const middlewares = Array.isArray(to.meta.middlewares) ? to.meta.middlewares : [to.meta.middlewares]
        const context = { from, to, next }
        const nextMiddleware = nextFactory(context, middlewares, 1)
        const auths = to.meta.authorize ? to.meta.authorize : []
        return middlewares[0](auths, { ...context, next: nextMiddleware })
    }

    return next()
})

export default router
