<template>
    <div class="index-view">
        <section class="mt-ui">
            <bxs-layout>
                <div class="flex nowrap">
                    <div class="flex nowrap">
                        <bxs-avatar
                        color="primary"
                        :src="$api.auth.me.img"
                        outlined
                        class="mr-ui"
                        @click="$router.push('/me')">
                            <span v-if="!$api.auth.me.img">{{ $api.auth.me.name[0] }}</span>
                        </bxs-avatar>

                        <div>
                            <h4>Archivio MP</h4>
                            <p class="text-mute"><small>{{ $api.auth.me.email }}</small></p>
                        </div>
                    </div>

                    <!-- <bxs-spacer></bxs-spacer> -->

                    <!-- <bxs-btn append-icon="">Esci</bxs-btn> -->
                </div>
            </bxs-layout>
        </section>

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <!-- <section>
            <bxs-layout>
                <bxs-text-field
                v-model="keywords"
                solo
                clearable
                name="keywords"
                label="Cerca le opere"
                class="mb-0">
                    <template #prepend>
                        <bxs-icon
                        name="search"
                        width="1.25em"></bxs-icon>
                    </template>
                </bxs-text-field>
            </bxs-layout>
        </section> -->

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <section-latest-works />

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <section>
            <bxs-layout>
                <bxs-list>
                    <bxs-list-item
                    to="/works"
                    append-icon="chevron-right">Tutte le opere</bxs-list-item>

                    <bxs-list-item
                    to="/works/new/edit"
                    color="primary"
                    append-icon="plus">Aggiungi opera</bxs-list-item>
                </bxs-list>
            </bxs-layout>
        </section>

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <section>
            <bxs-layout>
                <div>
                    <bxs-title>Statistiche</bxs-title>
                </div>

                <bxs-data-lazy :call="() => $api.getStatus()">
                    <template #data="{ data }">
                        <bxs-row>
                            <bxs-col cols="6">
                                <bxs-card class="pa-ui">
                                    <h4 class="mb-1 text-500">1</h4>
                                    <small>Lorem ipsum</small>
                                </bxs-card>
                            </bxs-col>

                            <bxs-col cols="6">
                                <bxs-card class="pa-ui">
                                    <h4 class="mb-1 text-500">2</h4>
                                    <small>Lorem ipsum</small>
                                </bxs-card>
                            </bxs-col>

                            <bxs-col cols="6">
                                <bxs-card class="pa-ui">
                                    <h4 class="mb-1 text-500">
                                        <span>1</span>
                                    </h4>
                                    <small>Lorem ipsum</small>
                                </bxs-card>
                            </bxs-col>

                            <bxs-col cols="6">
                                <bxs-card
                                src="/img/gradient-1.webp"
                                class="pa-ui">
                                    <h4 class="mb-1 text-500">{{ data.works_count }}</h4>
                                    <small>Nr. opere</small>
                                </bxs-card>
                            </bxs-col>
                        </bxs-row>
                    </template>
                </bxs-data-lazy>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'home',
    data () {
        return {
            keywords: null
        }
    },
    methods: {
        async handleClickNewOrder () {
            const id = await this.$api.orders.create({
                salesagent: this.$api.me.id
            })

            this.$router.push('/orders/' + id)
        }
    }
}
</script>
