<template>
    <div
    :class="classes"
    :style="styles">
        <slot />
    </div>
</template>

<script>
import {
    ratio,
    measurable,
    routable
} from '@/mixins'

export default {
    name: 'bxs_avatar',
    mixins: [ratio, measurable, routable],
    props: {
        color: String,
        src: String,
        outlined: Boolean
    },
    computed: {
        classes () {
            return ['bxs-avatar', {
                'bxs-avatar-outlined': this.outlined,
                'bxs-link': !!this.to,
                ...this.ratio_classes,
                ...this.routable_classes
            }]
        },
        styles () {
            const v = {
                ...this.measurable_styles
            }

            if (this.src) {
                v.backgroundImage = 'url(' + this.src + ')'
            }

            return v
        }
    }
}
</script>

<style lang="scss" scoped>
.bxs-avatar {
    position: relative;

    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    width: var(--avatar-size);
    height: var(--avatar-size);

    line-height: normal;

    border-radius: var(--avatar-border-radius);
    background-color: var(--avatar-background-color);
    // overflow: hidden;

    background-size: cover;
    background-repeat: no-repeat;

    &.bxs-avatar-outlined {
        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: 100%;
            height: 100%;
            border-radius: 100%;
            border: 1px solid var(--avatar-border-color);
            transform: scale(1.15);
        }
    }
}
</style>