<template>
    <header class="navframe">
        <bxs-toolbar>
            <logo />

            <bxs-spacer></bxs-spacer>

            <bxs-menu
            v-if="!!$api.auth.me"
            icon>
                <bxs-list>
                    <bxs-list-item @click="signout">Logout</bxs-list-item>
                </bxs-list>
            </bxs-menu>
        </bxs-toolbar>
    </header>
</template>

<script>
export default {
    name: 'bxs-navbar',
    methods: {
        async signout () {
            await this.$api.auth.signout()
            this.$router.go()
        }
    }
}
</script>

<style lang="scss" scoped>
.navframe {
    position: relative;
    width: 100%;
    min-height: var(--navbar-height);
    background-color: var(--navbar-background-color);
    display: flex;
    align-items: center;
    margin-bottom: var(--navbar-margin-bottom);
}
</style>