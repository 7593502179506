<template>
    <div class="order-edit-view">
        <bxs-top-nav>
            <span>{{ is_new ? 'Aggiungi' : 'Modifica' }} opera</span>

            <template #append>
                <bxs-menu
                icon
                title="Menu opera">
                    <bxs-list>
                        <bxs-list-item
                        prepend-icon="edit"
                        @click="handleUndoConfirm('submit')">Salva ed esci</bxs-list-item>

                        <bxs-list-item
                        prepend-icon="chevron-left"
                        @click="undo">Esci</bxs-list-item>

                        <bxs-list-item
                        v-if="!is_new"
                        prepend-icon="plus"
                        to="/works/new/edit">Nuova opera</bxs-list-item>

                        <bxs-list-item
                        v-if="!is_new"
                        prepend-icon="trash"
                        @click="show_pre_trash = true">Elimina opera</bxs-list-item>
                    </bxs-list>
                </bxs-menu>
            </template>
        </bxs-top-nav>

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
            stepper
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <section>
            <bxs-layout v-if="!preloaded">
                <bxs-loader></bxs-loader>
            </bxs-layout>

            <bxs-layout v-else>
                <bxs-form
                ref="form"
                @submit="save">
                    <!-- :conditions="{
                        base: [() => $refs.form.validate(['title'])]
                    }" -->
                    <bxs-stepper
                    ref="stepper"
                    v-model="step"
                    :items="steps"
                    @change:before="$refs.form.validate()"
                    @change:after="$refs.form.resetValidation()">
                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
                            step base
                        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <template #item.base>
                            <div class="mb-ui">
                                <p>Informazioni base</p>
                            </div>

                            <div class="mb-ui">
                                <bxs-row class="mb-2">
                                    <bxs-col cols="6">
                                        <bxs-file-uploader
                                        v-model="model.front_img.model"
                                        label="Immagine fronte" />
                                    </bxs-col>

                                    <bxs-col>
                                        <bxs-file-uploader
                                        v-model="model.back_img.model"
                                        label="Immagine retro" />
                                    </bxs-col>
                                </bxs-row>

                                <bxs-file-uploader
                                v-model="model.imgs.model"
                                label="Immagini particolari"
                                multiple />
                            </div>

                            <bxs-row align="center">
                                <bxs-col cols="12">
                                    <bxs-textarea-field
                                    v-model="model.title.model"
                                    label="Titolo"
                                    name="title"
                                    required
                                    clearable />
                                </bxs-col>

                                <bxs-col cols="8">
                                    <bxs-text-field
                                    v-model="model.numero.model"
                                    name="numero"
                                    label="Numero / ID"
                                    clearable />
                                </bxs-col>

                                <bxs-col cols="4">
                                    <bxs-text-field
                                    v-model="model.anno.model"
                                    name="anno"
                                    label="Anno"
                                    clearable />
                                </bxs-col>

                                <bxs-col cols="12">
                                    <bxs-text-field
                                    v-model="model.categoria.model"
                                    name="categoria"
                                    label="Categoria" />
                                </bxs-col>

                                <bxs-col cols="12">
                                    <bxs-text-field
                                    v-model="model.tecnica.model"
                                    name="tecnica"
                                    label="Tecnica"
                                    clearable />
                                </bxs-col>

                                <bxs-col cols="12">
                                    <bxs-text-field
                                    v-model="model.supporto.model"
                                    name="supporto"
                                    label="Supporto"
                                    clearable />
                                </bxs-col>

                                <!-- <bxs-col cols="12">
                                    <bxs-text-field
                                    v-model="model.soggetto.model"
                                    label="Soggetto"
                                    name="soggetto" />
                                </bxs-col> -->
                            </bxs-row>
                        </template>

                        <template #item.dimensioni>
                            <div class="mb-ui">
                                <p>Dimensioni</p>
                            </div>

                            <bxs-row>
                                <bxs-col cols="6">
                                    <bxs-text-field
                                    v-model="model.dimensioni.height.model"
                                    name="dimensioni.height"
                                    label="Altezza"
                                    suffix="cm"
                                    clearable />
                                </bxs-col>

                                <bxs-col cols="6">
                                    <bxs-text-field
                                    v-model="model.dimensioni.width.model"
                                    name="dimensioni.width"
                                    label="Larghezza"
                                    suffix="cm"
                                    clearable />
                                </bxs-col>

                                <bxs-col cols="6">
                                    <bxs-text-field
                                    v-model="model.dimensioni.depth.model"
                                    name="dimensioni.depth"
                                    label="Profondità"
                                    suffix="cm"
                                    clearable />
                                </bxs-col>

                                <bxs-col cols="6">
                                    <bxs-text-field
                                    v-model="model.dimensioni.weight.model"
                                    name="dimensioni.weight"
                                    label="Peso"
                                    suffix="cm"
                                    clearable />
                                </bxs-col>
                            </bxs-row>
                        </template>

                        <template #item.organizzazione>
                            <div class="mb-ui">
                                <p>Organizzazione</p>
                            </div>

                            <bxs-row>
                                <bxs-col cols="12">
                                    <bxs-text-field
                                    v-model="model.categoria.model"
                                    name="categoria"
                                    label="Categoria"
                                    clearable />
                                </bxs-col>

                                <!-- <bxs-col cols="12">
                                    <bxs-text-field
                                    v-model="model.tipologia.model"
                                    label="Tipologia" />
                                </bxs-col> -->

                                <bxs-col cols="12">
                                    <bxs-text-field
                                    v-model="model.collezione.model"
                                    name="collezione"
                                    label="Collezione"
                                    clearable />
                                </bxs-col>

                                <bxs-col cols="12">
                                    <bxs-text-field
                                    v-model="model.collezione_desunta.model"
                                    name="collezione_desunta"
                                    label="Collezione desunta"
                                    clearable />
                                </bxs-col>

                                <bxs-col cols="12">
                                    <bxs-text-field
                                    v-model="model.numero_quaderno_serigrafie.model"
                                    name="numero_quaderno_serigrafie"
                                    label="Numero quaderno serigrafie"
                                    clearable />
                                </bxs-col>

                                <bxs-col cols="12">
                                    <bxs-switch-field
                                    v-model="model.in_quaderno_veline.model"
                                    name="in_quaderno_veline"
                                    label="Quaderno veline"
                                    class="mb-1" />
                                </bxs-col>

                                <bxs-col cols="12">
                                    <bxs-switch-field
                                    v-model="model.in_quaderno_serigrafie.model"
                                    name="in_quaderno_serigrafie"
                                    label="Quaderno serigrafie"
                                    class="mb-1" />
                                </bxs-col>

                                <bxs-col cols="12">
                                    <bxs-switch-field
                                    v-model="model.è_firmata.model"
                                    name="è_firmata"
                                    label="Firmata"
                                    class="mb-1" />
                                </bxs-col>

                                <bxs-col cols="12">
                                    <bxs-switch-field
                                    v-model="model.è_multiplo.model"
                                    name="è_multiplo"
                                    label="Multiplo" />
                                </bxs-col>
                            </bxs-row>
                        </template>

                        <template #item.valori>
                            <div class="mb-ui">
                                <p>Valori</p>
                            </div>

                            <bxs-row>
                                <bxs-col cols="12">
                                    <bxs-text-field
                                    v-model="model.valore_opera.model"
                                    name="valore_opera"
                                    label="Valore opera"
                                    clearable />
                                </bxs-col>

                                <bxs-col cols="12">
                                    <bxs-text-field
                                    v-model="model.valore_assicurativo.model"
                                    name="valore_assicurativo"
                                    label="Valore assicurativo"
                                    clearable />
                                </bxs-col>
                            </bxs-row>
                        </template>

                        <template #item.cassa>
                            <div class="mb-ui">
                                <p>Collocazione</p>
                            </div>

                            <bxs-row class="mb-ui">
                                <bxs-col cols="12">
                                    <bxs-text-field
                                    v-model="model.collocazione.model"
                                    name="collocazione"
                                    label="Collocazione"
                                    clearable />
                                </bxs-col>

                                <bxs-col cols="12">
                                    <bxs-text-field
                                    v-model="model.tipo_imballo.model"
                                    name="tipo_imballo"
                                    label="Tipologia imballo"
                                    clearable />
                                </bxs-col>
                            </bxs-row>

                            <bxs-switch-field
                            v-model="model.cassa.è_presente.model"
                            name="cassa.è_presente"
                            label="Cassa opera"
                            class="mb-ui" />

                            <work-cassa-field
                            v-if="model.cassa.è_presente.model"
                            v-model="model.cassa.value.model" />
                        </template>

                        <template #item.autentica>
                            <div class="mb-ui">
                                <p>Autentica</p>
                            </div>

                            <bxs-switch-field
                            v-model="model.autentica.è_presente.model"
                            name="autentica.è_presente"
                            label="Opera autenticata" />

                            <div
                            v-if="model.autentica.è_presente.model"
                            class="mt-ui">
                                <bxs-file-uploader
                                v-model="model.autentica.imgs.model"
                                multiple
                                class="mb-2" />

                                <bxs-text-field
                                v-model="model.autentica.richiedente.model"
                                name="richiedente"
                                label="Richiedente"
                                clearable />

                                <bxs-text-field
                                v-model="model.autentica.data_rilascio.model"
                                name="autentica.data_rilascio"
                                label="Data rilascio Pistoletto"
                                clearable />

                                <bxs-text-field
                                v-model="model.autentica.info.model"
                                name="autentica.info"
                                label="Note"
                                clearable />
                            </div>
                        </template>

                        <template #item.extra>
                            <div class="mb-ui">
                                <p>Extra</p>
                            </div>

                            <bxs-row class="mb-ui">
                                <bxs-col cols="12">
                                    <bxs-textarea-field
                                    v-model="model.iscrizioni_su_retro.model"
                                    name="iscrizioni_su_retro"
                                    label="Isrizioni su retro"
                                    clearable />
                                </bxs-col>

                                <bxs-col cols="12">
                                    <bxs-textarea-field
                                    v-model="model.bibliografia.model"
                                    name="bibliografia"
                                    label="Bibliografia"
                                    clearable />
                                </bxs-col>

                                <bxs-col cols="12">
                                    <bxs-textarea-field
                                    v-model="model.note.model"
                                    name="note"
                                    label="Note"
                                    clearable />
                                </bxs-col>
                            </bxs-row>
                        </template>

                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
                            bottom navs
                        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <template #actions="{ is_last_step, is_first_step, prev, next }">
                            <!-- step control -->
                            <bxs-bottom-nav v-if="!is_last_step">
                                <bxs-toolbar color="background">
                                    <bxs-btn
                                    :disabled="is_first_step"
                                    icon
                                    @click="prev">
                                        <bxs-icon name="chevron-left"></bxs-icon>
                                    </bxs-btn>

                                    <bxs-btn
                                    block
                                    color="black"
                                    class="mx-ui"
                                    @click="save('save')">Salva</bxs-btn>

                                    <bxs-btn
                                    icon
                                    @click="next">
                                        <bxs-icon name="chevron-right"></bxs-icon>
                                    </bxs-btn>
                                </bxs-toolbar>
                            </bxs-bottom-nav>

                            <!-- recap  -->
                            <bxs-bottom-nav v-else>
                                <bxs-toolbar color="background">
                                    <bxs-btn
                                    block
                                    dark
                                    color="info"
                                    @click="save('save')">Salva</bxs-btn>

                                    <!-- <bxs-btn
                                    block
                                    dark
                                    color="success"
                                    class="ml-2"
                                    @click="save('confirm')">Finalizza opera</bxs-btn> -->
                                </bxs-toolbar>
                            </bxs-bottom-nav>
                        </template>
                    </bxs-stepper>
                </bxs-form>
            </bxs-layout>
        </section>
        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
            end stepper
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
            trash
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <bxs-menu
        v-model="show_pre_trash"
        closable
        solo>
            <template #header>
                <h6>Elimina opera</h6>
                <p class="text-mute">Vuoi eliminare questa commessa?</p>
            </template>

            <template #actions>
                <div class="flex pa-layout">
                    <bxs-btn
                    block
                    color="error"
                    class="mr-2"
                    @click="trash">Elimina</bxs-btn>

                    <bxs-btn
                    block
                    color="info"
                    class="ml-2"
                    @click="show_pre_trash = false">Annulla</bxs-btn>
                </div>
            </template>
        </bxs-menu>

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
            undo
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <bxs-menu
        v-model="show_pre_undo"
        closable
        solo>
            <template #header>
                <h5>Esci dalla {{ is_new ? 'creazione' : 'modifica' }}</h5>
                <p class="text-mute">Cosa vuoi fare con l'opera su cui stai lavorando?</p>
            </template>

            <template #actions>
                <div class="flex nowrap py-ui px-layout">
                    <bxs-btn
                    block
                    color="info"
                    class="mr-1"
                    @click="handleUndoConfirm('submit')">Salva</bxs-btn>

                    <bxs-btn
                    block
                    color="error"
                    class="ml-1"
                    @click="handleUndoConfirm('undo')">Esci</bxs-btn>
                </div>
            </template>
        </bxs-menu>
    </div>
</template>

<script>
import {
    // assignIn,
    isEqual
} from 'lodash'
import dotObj from 'dot-object'

dotObj.keepArray = true

export default {
    name: 'work_edit',
    data () {
        return {
            preloaded: false,
            step: 0,
            steps: [
                { text: 'Informazioni base', value: 'base' },
                { text: 'Dimensioni', value: 'dimensioni' },
                { text: 'Organizzazione', value: 'organizzazione' },
                { text: 'Valori', value: 'valori' },
                { text: 'Cassa', value: 'cassa' },
                { text: 'Autentica', value: 'autentica' },
                { text: 'Extra', value: 'extra' }
            ],
            loading: false,
            show_pre_undo: false,
            show_pre_trash: false,
            is_save_real_time_data: false,
            //
            model: null,
            old_model: {},
            tmp_id: null
        }
    },
    computed: {
        is_new () {
            return this.$route.params.id === 'new'
        },
        is_modified () {
            return !isEqual(this.model, this.old_model)
        },
        ID () {
           return this.is_new ? null : this.$route.params.id
        }
    },
    //
    beforeMount() {
        console.log('> Work edit beforeMount()')
    },
    mounted () {
        this.$nextTick(this.start)
    },
    //
    watch: {
        step () {
            if (this.is_save_real_time_data) {
                this.save('save')
            }
        },
        model: {
            deep: true,
            handler () {
                console.log('> Work edit > watch model')
            }
        }
    },
    methods: {
        // ------------------------------------------------------------------------------------------------------------------------------------
        // controls
        // ------------------------------------------------------------------------------------------------------------------------------------
        async start () {
            console.log('> Work edit start()', this.is_new, this.ID)
            let doc = null

            try {
                await this.$api.auth.refreshAccess()
            } catch (err) {
                console.log(err)
            }

            if (!this.is_new) {
                doc = await this.$api.works.doc(this.ID)
            }

            // model
            const model = await this.$api.getSchema('Work', 'obj')
            this.model = {}

            for (const k in model) {
                const field = model[k]

                this.model[k] = {
                    ...field,
                    model: this.is_new ? field.default : dotObj.pick(k, doc)
                }
            }

            //
            this.old_model = JSON.parse(JSON.stringify(this.model))
            this.preloaded = true
        },
        async stop () {
            console.log('> Work edit stop()')

            // this.$emitter.$off('hook:beforeUnmount')

            this.loading = false
            this.show_pre_undo = false
            this.is_save_real_time_data = false

            const model = await this.$api.getSchema('Work', 'obj')
            this.model = {}

            for (const k in model) {
                const field = model[k]

                this.model[k] = {
                    ...field,
                    model: field.default
                }
            }
        },
        preventNav (evt) {
            console.log('> Work edit > preventNav()', evt)
            this.show_pre_undo = true
            evt.preventDefault()
            evt.returnValue = ''
        },
        undo () {
            this.show_pre_undo = true
        },
        async handleUndoConfirm (fn) {
            if (fn === 'submit') await this.save('save')
            this.$router.go(-1)
        },
        // ------------------------------------------------------------------------------------------------------------------------------------
        // actions
        // ------------------------------------------------------------------------------------------------------------------------------------
        async trash () {
            console.log('> Order edit > trash()')

            this.$store.commit('set_is_loading', true)
            this.loading = true

            try {
                await this.$tfork.orders.remove(this.ID)
                this.$router.replace('/')
            } catch (err) {
                throw new Error(err.message || err.code || err)
            } finally {
                this.loading = false
                this.$store.commit('set_is_loading', false)
            }
        },
        async save (action) {
            console.log('> Order edit > save()', action, this.is_new)

            const form_validate = this.$refs.form.validate()

            if (!this.model.title.model) {
                this.$refs.stepper.to('base')
                throw new Error('Attenzione, titolo richiesto')
            }

            if (!form_validate) {
                this.$toast.warning('Attenzione ai campi richiesti')
                return
            }

            this.$store.commit('set_is_loading', true)
            this.loading = true

            try {
                const model = dotObj.dot(this.model)
                const body = {}

                for (const k in model) {
                    if (k.includes('.model')) {
                        const key = k.replace('.model', '')
                        body[key] = model[k]
                    }
                }

                const _id = await this.$api.works[action](this.ID, body)

                if (this.is_new) {
                    this.$router.push('/works/' + _id + '/edit')
                }

                this.$toast.success('Opera salvata')
            } catch (err) {
                throw new Error(err.message || err.code || err)
            } finally {
                this.loading = false
                this.$store.commit('set_is_loading', false)
            }
        }
    },
    //
    beforeUnmount () {
        console.log('beforeUnmount')
        this.stop()
    }
    // beforeRouteLeave (to, from, next) {
    //     const answer = window.confirm('Vuoi davvero uscire? Attenzione ai cambiamenti non salvati!')
    //     this.show_pre_undo = true

    //     if (answer) next()
    //     else next(false)
    // }
}
</script>