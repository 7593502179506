<template>
    <div class="me-view">
        <bxs-top-nav>Profilo</bxs-top-nav>

        <section v-if="$api.auth.me">
            <bxs-layout>
                <div class="flex nowrap">
                    <bxs-avatar
                    color="primary"
                    :src="$api.auth.me.img"
                    outlined
                    class="mr-ui">
                        <span v-if="!$api.auth.me.img">{{ $api.auth.me.name[0] }}</span>
                    </bxs-avatar>

                    <div v-if="$api.auth.me">
                        <h4>{{ $api.auth.me.name }}</h4>
                        <p class="text-mute"><small>{{ $api.auth.me.email }}</small></p>
                    </div>
                </div>
            </bxs-layout>
        </section>

        <!-- <section>
            <bxs-layout>
                <p>{{ $api.auth.me.name }}</p>
                <p class="text-mute">{{ $api.auth.me.email }}</p>
            </bxs-layout>
        </section> -->

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <section-latest-edit-works />

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <section>
            <bxs-layout>
                <bxs-list>
                    <bxs-list-item
                    divider
                    prepend-icon="info"
                    href="mailto:simon3fusco@gmail.com">Bisogno di aiuto?</bxs-list-item>

                    <bxs-list-item
                    prepend-icon="chevron-left"
                    @click="signout">Logout</bxs-list-item>
                </bxs-list>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'me',
    data () {
        return {
            data_page: null
        }
    },
    methods: {
        async signout () {
            try {
                this.$store.commit('set_is_loading', true)
                await this.$api.auth.signout()
                this.$router.go()
            } catch (err) {
                throw new Error(err.message || err.code || err)
            } finally {
                this.$store.commit('set_is_loading', false)
            }
        }
    }
}
</script>