import { createApp, reactive } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import Api from './assets/libs/Api'
import components from './components'
import directives from './directives'
import filters from './filters'
import { useToast } from 'vue-toastification'
import Emitter from 'tiny-emitter'

const emitter = new Emitter()
const app = createApp(App)

const api = new Api({
    app_id: 'laci',
    prefix: 'fpl'
})

app.config.globalProperties.$emitter = {
    $on: (...args) => emitter.on(...args),
    $once: (...args) => emitter.once(...args),
    $off: (...args) => emitter.off(...args),
    $emit: (...args) => emitter.emit(...args)
}
app.config.globalProperties.$filters = filters
app.config.globalProperties.$api = reactive(api)
window.$api = app.config.globalProperties.$api
components(app)
directives(app)

app.config.globalProperties.$toast = useToast()

app.config.errorHandler = (err, vm, info) => {
    console.log('error handler', err, vm, info)
    // const payload = Object.values(err.payload || {})
    let message = err.message || err.code || err

    if (err.code === 'resource.validation') {
        message += Object.values(err.fields)
    }

    vm.$toast.error(String(message))
}

app.use(store).use(router)

router.isReady().then(() => {
    app.mount('#app', true)
})