<template>
    <div class="order-view">
        <bxs-top-nav>
            <span>{{ item ? item.title : '' }}</span>

            <template #append>
                <bxs-menu
                v-if="item"
                icon
                title="Menu opera">
                    <bxs-list>
                        <bxs-list-item
                        append-icon="edit"
                        :to="`/works/${item.id}/edit`">Modifica opera</bxs-list-item>

                        <bxs-list-item
                        v-if="$api.auth.is_dev"
                        append-icon="copy">Duplica opera</bxs-list-item>

                        <bxs-list-item
                        append-icon="plus"
                        to="/works/new/edit">Aggiungi nuova opera</bxs-list-item>
                    </bxs-list>
                </bxs-menu>
            </template>
        </bxs-top-nav>

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <bxs-data-lazy
        v-model="item"
        :call="() => $api.works.doc($route.params.id, { populate: 'front_img back_img imgs autentica.imgs' })">
            <section class="black">
                <bxs-layout>
                    <p class="mb-0 text-line-height-normal">{{ item.title }}</p>
                </bxs-layout>
            </section>

            <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
            ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
            <section class="black">
                <bxs-layout>
                    <bxs-row class="mb-ui">
                        <bxs-col cols="6">
                            <h6 class="mb-1">Fronte</h6>
                            <bxs-figure
                            v-if="item.front_img"
                            :src="item.front_img.url"
                            ratio="1"
                            class="bxs-pointer"
                            @click="showImg(item.front_img)" />

                            <bxs-figure
                            v-else
                            ratio="1" />
                        </bxs-col>

                        <bxs-col cols="6">
                            <h6 class="mb-1">Retro</h6>
                            <bxs-figure
                            v-if="item.back_img"
                            :src="item.back_img.url"
                            ratio="1"
                            class="bxs-pointer"
                            @click="showImg(item.back_img)" />
                            <bxs-figure
                            v-else
                            ratio="1" />
                        </bxs-col>
                    </bxs-row>

                    <div>
                        <h6 class="mb-1">Particolari</h6>
                        <bxs-carousel
                        v-if="item.imgs.length > 0"
                        :items="item.imgs"
                        :autoplay="item.imgs.length > 3"
                        navigation>
                            <template #item="{ item }">
                                <bxs-figure
                                :src="item.url"
                                width="120"
                                ratio="1"
                                class="bxs-pointer"
                                @click="showImg(item)" />
                            </template>
                        </bxs-carousel>
                        <bxs-figure
                        v-else
                        width="120"
                        ratio="1" />
                    </div>
                </bxs-layout>
            </section>

            <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
            ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
            <section>
                <bxs-layout>
                    <bxs-row style="height: 60px;">
                        <bxs-col class="text-center flex column justify-center bxs-truncate">
                            <p class="text-uppercase text-mute mb-1">Numero</p>
                            <p class="text-500">{{ item.numero }}</p>
                        </bxs-col>

                        <bxs-col class="text-center">
                            <div class="bxs-divider bxs-divider-vertical"></div>
                        </bxs-col>

                        <bxs-col class="text-center flex column justify-center bxs-truncate">
                            <p class="text-uppercase text-mute mb-1">Anno</p>
                            <p class="text-500">{{ item.anno }}</p>
                        </bxs-col>
                    </bxs-row>
                </bxs-layout>
            </section>

            <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
            ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
            <section class="black">
                <bxs-layout>
                    <p>Informazioni base</p>
                </bxs-layout>
            </section>

            <section>
                <bxs-layout>
                    <div class="mb-ui">
                        <h6 class="text-mute">Tecnica</h6>
                        <p>{{ item.tecnica || '--' }}</p>
                    </div>

                    <div class="mb-ui">
                        <h6 class="text-mute">Supporto</h6>
                        <p>{{ item.supporto || '--' }}</p>
                    </div>

                    <div class="mb-ui">
                        <h6 class="text-mute">Soggetto</h6>
                        <p>{{ item.soggetto || '--' }}</p>
                    </div>
                </bxs-layout>
            </section>

            <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
            ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
            <section class="black">
                <bxs-layout>
                    <p>Dimensioni</p>
                </bxs-layout>
            </section>

            <section>
                <bxs-layout>
                    <div class="mb-ui">
                        <h6 class="text-mute">Altezza</h6>
                        <p>{{ item.dimensioni.height || '--' }}</p>
                    </div>

                    <div class="mb-ui">
                        <h6 class="text-mute">Larghezza</h6>
                        <p>{{ item.dimensioni.width || '--' }}</p>
                    </div>

                    <div class="mb-ui">
                        <h6 class="text-mute">Profondità</h6>
                        <p>{{ item.dimensioni.depth || '--' }}</p>
                    </div>

                    <div class="mb-ui">
                        <h6 class="text-mute">Peso</h6>
                        <p>{{ item.dimensioni.weight || '--' }}</p>
                    </div>

                    <div class="mb-ui">
                        <h6 class="text-mute">Tipo imballo</h6>
                        <p>{{ item.tipo_imballo || '--' }}</p>
                    </div>
                </bxs-layout>
            </section>

            <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
            ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
            <section class="black">
                <bxs-layout>
                    <p>Organizzazione</p>
                </bxs-layout>
            </section>

            <section>
                <bxs-layout>
                    <div class="mb-ui">
                        <h6 class="text-mute">Categoria</h6>
                        <p>{{ item.categoria || '--' }}</p>
                    </div>

                    <div class="mb-ui">
                        <h6 class="text-mute">Collezione</h6>
                        <p>{{ item.collezione || '--' }}</p>
                    </div>

                    <div class="mb-ui">
                        <h6 class="text-mute">Collezione desunta</h6>
                        <p>{{ item.collezione_desunta || '--' }}</p>
                    </div>

                    <div class="mb-ui">
                        <h6 class="text-mute">Collocazione</h6>
                        <p>{{ item.collocazione || '--' }}</p>
                    </div>
                </bxs-layout>
            </section>

            <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
            ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
            <section class="black">
                <bxs-layout>
                    <p>Cassa</p>
                </bxs-layout>
            </section>

            <section>
                <bxs-layout>
                    <div class="mb-ui">
                        <h6 class="text-mute">Opera con una o più casse</h6>
                        <p>{{ item.cassa.è_presente ? 'Si' : 'No' }}</p>
                    </div>

                    <div v-if="item.cassa.è_presente && item.cassa.value.length > 0">
                        <ul>
                            <li
                            v-for="(cassa, i) in item.cassa.value"
                            :key="i"
                            style="background-color: rgb(15, 15, 15);">
                                <div class="flex nowrap align-start pa-ui">
                                    <bxs-figure
                                    :src="cassa.img ? cassa.img.url : null"
                                    width="80"
                                    ratio="1"
                                    class="bxs-pointer mr-ui"
                                    @click="showImg(item)"  />

                                    <div class="small">
                                        <div class="mb-2">
                                            <p class="text-mute">Codice</p>
                                            <p>{{ cassa.numero_riferimento || '--' }}</p>
                                        </div>

                                        <div class="mb-2">
                                            <p class="text-mute">Titolo</p>
                                            <p>{{ cassa.titolo || '--' }}</p>
                                        </div>

                                        <div class="mb-2">
                                            <p class="text-mute">Peso</p>
                                            <p>{{ cassa.peso || '--' }} kg</p>
                                        </div>
                                    </div>
                                </div>

                                <bxs-divider v-if="i < item.cassa.value.length - 1"></bxs-divider>
                            </li>
                        </ul>
                    </div>
                </bxs-layout>
            </section>

            <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
            ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
            <section class="black">
                <bxs-layout>
                    <p>Autentica</p>
                </bxs-layout>
            </section>

            <section>
                <bxs-layout>
                    <div class="mb-ui">
                        <h6 class="text-mute">Opera autenticata</h6>
                        <p>{{ item.autentica.è_presente ? 'Si' : 'No' }}</p>
                    </div>

                    <div v-if="item.autentica.è_presente">
                        <bxs-carousel
                        v-if="item.autentica.imgs"
                        :items="item.autentica.imgs"
                        :autoplay="item.autentica.imgs.length > 3"
                        navigation
                        class="mb-ui">
                            <template #item="{ item }">
                                <bxs-figure
                                :src="item.url"
                                width="60"
                                ratio="1"
                                class="bxs-pointer"
                                @click="showImg(item)" />
                            </template>
                        </bxs-carousel>

                        <div class="mb-ui">
                            <h6 class="text-mute">Richiedente</h6>
                            <p>{{ item.autentica.richiedente || '--' }}</p>
                        </div>

                        <div class="mb-ui">
                            <h6 class="text-mute">Data rilascio Pistoletto</h6>
                            <p>{{ item.autentica.data_rilascio || '--' }}</p>
                        </div>

                        <div class="mb-ui">
                            <h6 class="text-mute">Note autentica</h6>
                            <p>{{ item.autentica.info || '--' }}</p>
                        </div>
                    </div>
                </bxs-layout>
            </section>

            <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
            ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
            <section class="black">
                <bxs-layout>
                    <p>Extra</p>
                </bxs-layout>
            </section>

            <section>
                <bxs-layout>
                    <div class="mb-ui">
                        <h6 class="text-mute">Iscrizioni su retro</h6>
                        <p>{{ item.iscrizioni_su_retro || '--' }}</p>
                    </div>

                    <div class="mb-ui">
                        <h6 class="text-mute">Bibliografia</h6>
                        <p>{{ item.bibliografia || '--' }}</p>
                    </div>

                    <div class="mb-ui">
                        <h6 class="text-mute">Note</h6>
                        <p>{{ item.note || '--' }}</p>
                    </div>
                </bxs-layout>
            </section>
        </bxs-data-lazy>

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <bxs-drawer
        v-model="modals.img.on"
        :title="modals.img.item ? modals.img.item.name : null">
            <div
            v-if="modals.img.loading"
            class="text-center">
                <bxs-loader></bxs-loader>
            </div>

            <div v-else-if="!modals.img.loading && item">
                <bxs-figure
                :src="modals.img.item.url"
                class="mb-ui" />

                <div
                v-if="modals.img.item.tags.length > 0"
                class="mb-ui">
                    <bxs-chip
                    v-for="(c, i) in modals.img.item.tags"
                    :key="i"
                    class="mr-1">{{ c }}</bxs-chip>
                </div>

                <div class="mb-ui">
                    <h6 class="text-line-height-normal">{{ modals.img.item.name }}</h6>
                    <p>
                        <i>{{ modals.img.item.dimension.w }} * {{ modals.img.item.dimension.h }} px</i>
                    </p>
                </div>

                <div class="mb-ui">
                    <h6 class="text-mute">Fotografo</h6>
                    <p>{{ modals.img.item.fotografo || '--' }}</p>
                </div>

                <div class="mb-ui">
                    <h6 class="text-mute">Luogo scatto</h6>
                    <p>{{ modals.img.item.luogo_scatto || '--' }}</p>
                </div>

                <div class="mb-ui">
                    <h6 class="text-mute">Data scatto</h6>
                    <p>{{ modals.img.item.data_scatto || '--' }}</p>
                </div>

                <div>
                    <h6 class="text-mute">Note</h6>
                    <p>{{ modals.img.item.note || '--' }}</p>
                </div>
            </div>

            <!-- <template #actions>
                <div class="px-layout">
                    <bxs-btn icon>
                        <bxs-icon name="share"></bxs-icon>
                    </bxs-btn>
                </div>
            </template> -->
        </bxs-drawer>

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <!-- <bxs-bottom-nav>
            <bxs-toolbar>
                <bxs-btn
                icon
                fab
                to="/">
                    <bxs-icon name="envelope"></bxs-icon>
                </bxs-btn>

                <bxs-spacer></bxs-spacer>

                <bxs-btn
                icon
                fab
                to="/">
                    <bxs-icon name="share"></bxs-icon>
                </bxs-btn>

                <bxs-spacer></bxs-spacer>

                <bxs-btn
                icon
                fab
                to="/">
                    <bxs-icon name="print"></bxs-icon>
                </bxs-btn>
            </bxs-toolbar>
        </bxs-bottom-nav> -->
    </div>
</template>

<script>
export default {
    name: 'work',
    data () {
        return {
            item: null,
            modals: {
                img: {
                    loading: true,
                    on: false,
                    item: null
                }
            }
        }
    },
    watch: {
        item () {
            if (this.item && this.item.cassa.value.length > 0) {
                this.item.cassa.value.forEach((item) => {
                    if (item.img) {
                        this.$api.files.doc(item.img, {
                            select: 'url'
                        }).then((doc) => {
                            item.img = doc
                        })
                    }
                })
            }
        }
    },
    methods: {
        showImg (item) {
            console.log('showImg', item)

            this.modals.img.loading = true
            this.modals.img.on = true

            this.$api.files.doc(item.id).then((doc) => {
                this.modals.img.item = doc
                this.modals.img.loading = false
            }).catch(() => {
                this.modals.img.loading = false
            })
        }
    }
}
</script>