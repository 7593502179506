<template>
    <div class="password-reset-view">
        <bxs-toolbar
        sticky
        tile
        flat>
            <bxs-btn
            icon
            @click="$router.go(-1)">
                <bxs-icon name="chevron-left"></bxs-icon>
            </bxs-btn>

            <bxs-spacer></bxs-spacer>

            <div class="bxs-truncate text-center mx-ui">
                <h4>Reset password</h4>
            </div>
        </bxs-toolbar>

        <section class="text-center">
            <bxs-layout>
                <div
                v-if="!user"
                class="mb-ui">
                    <p>Inserisci il codice ricevuto via email</p>
                </div>

                <bxs-form
                v-if="!user"
                ref="form_code"
                class="mb-ui"
                @submit="verifyCode">
                    <div class="flex nowrap mb-layout">
                        <bxs-text-field
                        v-model="code.n_1"
                        name="code_1"
                        required
                        max-length="1"
                        hide-counter
                        class="mr-2" />

                        <bxs-text-field
                        v-model="code.n_2"
                        name="code_2"
                        required
                        max-length="1"
                        hide-counter
                        min-length="1"
                        class="mr-2" />

                        <bxs-text-field
                        v-model="code.n_3"
                        name="code_3"
                        required
                        max-length="1"
                        hide-counter
                        class="mr-2" />

                        <bxs-text-field
                        v-model="code.n_4"
                        name="code_4"
                        required
                        max-length="1"
                        hide-counter
                        class="mr-2" />

                        <bxs-text-field
                        v-model="code.n_5"
                        name="code_5"
                        hide-counter
                        max-length="1"
                        required />
                    </div>

                    <bxs-btn
                    block
                    :disabled="loading"
                    :loading="loading"
                    @click="$refs.form_code.submit()">Verifica</bxs-btn>
                </bxs-form>

                <bxs-form
                v-else-if="user && !success"
                ref="form_pwd_reset"
                class="mb-ui"
                @submit="resetPassword">
                    <bxs-text-field
                    v-model="password"
                    required
                    label="Password"
                    type="password"
                    name="password"
                    class="mb-layout">
                        <template #append="{ on, value }">
                            <bxs-btn
                            v-on="on"
                            small>{{ !value ? 'show' : 'hide' }}</bxs-btn>
                        </template>
                    </bxs-text-field>

                    <bxs-text-field
                    v-model="confirm_password"
                    required
                    label="Conferma password"
                    type="password"
                    name="confirm_password"
                    class="mb-ui">
                        <template #append="{ on, value }">
                            <bxs-btn
                            v-on="on"
                            small>{{ !value ? 'show' : 'hide' }}</bxs-btn>
                        </template>
                    </bxs-text-field>

                    <bxs-btn
                    block
                    color="success"
                    light
                    :disabled="loading"
                    :loading="loading"
                    @click="$refs.form_pwd_reset.submit()">Cambia password</bxs-btn>
                </bxs-form>

                <div
                v-else
                class="mb-ui">
                    <h4>Passsword aggiornata!</h4>
                </div>

                <div
                v-if="!user"
                class="mb-ui">
                    <bxs-btn
                    text
                    to="/password/recovery">Non hai ricevuto il codice via email? Richiedi</bxs-btn>
                </div>

                <!-- undo -->
                <div>
                    <bxs-btn
                    v-if="!$store.state.access"
                    text
                    to="/signin">Hai già un account? Accedi</bxs-btn>

                    <bxs-btn
                    v-else
                    text
                    to="/me">Il mio profilo</bxs-btn>
                </div>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'password_reset',
    data () {
        return {
            password: null,
            confirm_password: null,
            code: {
                n_1: null,
                n_2: null,
                n_3: null,
                n_4: null,
                n_5: null
            },
            // code: null,
            user: null,
            success: false,

            error_reset: null,
            error_code: null,
            loading: false
        }
    },
    methods: {
        async verifyCode () {
            this.loading = true

            try {
                this.user = await this.$http.get('v1/auths/password_recovery', {
                    code: Object.values(this.code).join('')
                })
            } catch (err) {
                throw new Error(err.message || err.raw)
            } finally {
                this.loading = false
            }
        },
        async resetPassword () {
            this.loading = true

            try {
                await this.$http.patch('v1/auths/password_recovery', {
                    code: Object.values(this.code).join(''),
                    password: this.password,
                    confirm_password: this.confirm_password
                })

                this.success = true
            } catch (err) {
                throw new Error(err.message || err.raw)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>