<template>
    <section>
        <bxs-layout>
            <div class="flex align-center mb-ui">
                <bxs-title class="mb-0">Ultime opere</bxs-title>

                <bxs-spacer></bxs-spacer>

                <router-link
                to="/works"
                class="text-info">Vedi tutte</router-link>
            </div>

            <ul v-if="items.length > 0">
                <li
                v-for="item in items"
                :key="item.id"
                class="mb-2 mb-0-last">
                    <work-row :item="item" />
                </li>
            </ul>

            <div
            v-else
            class="text-center text-mute mt-ui py-ui">
                <p>Nessun opera da visualizzare al momento</p>
            </div>
        </bxs-layout>
    </section>
</template>

<script>
export default {
    name: 'latest_edit_work',
    data () {
        return {
            items: []
        }
    },
    created () {
        this.$api.works.list({
            limit: 5,
            pagination: false,
            spread: 'front_img',
            sort: { created_at: -1 }
            }).then((docs) => {
                this.items = docs
            })
    }
}
</script>