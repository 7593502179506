<template>
    <div class="work-cassa-field">
        <div class="flex nowrap align-center mb-ui">
            <p>Cassa</p>

            <bxs-spacer></bxs-spacer>

            <a
            v-if="local_value.length > 0"
            rel="button"
            class="text-info"
            @click="add">Aggiungi riga</a>
        </div>

        <div
        v-if="!local_value.length"
        class="text-center">
            <p class="text-mute mb-1">Aggiungi una una riga per la cassa opera</p>

            <bxs-btn
            text
            color="info"
            @click="add">Aggiungi riga</bxs-btn>
        </div>

        <!--  -->
        <ul v-else>
            <li
            v-for="(element, index) in local_value"
            :key="index"
            class="mb-ui">
                <div class="flex nowrap align-center mb-1">
                    <!-- <div
                    v-if="local_value.length > 1"
                    class="mr-1">
                        <bxs-icon name="grip-vertical" />
                    </div> -->

                    <small>Riga {{ index + 1 }}</small>

                    <bxs-spacer></bxs-spacer>

                    <a
                    role="button"
                    class="text-error small"
                    @click="remove(index)">Rimuovi</a>
                </div>

                <div>
                    <bxs-file-uploader
                    v-model="element.img"
                    class="mb-2" />

                    <bxs-text-field
                    v-model="element.titolo"
                    outlined
                    dense
                    label="Titolo"
                    class="mb-2" />

                    <bxs-text-field
                    v-model="element.numero_riferimento"
                    outlined
                    dense
                    label="Codice"
                    class="mb-2" />

                    <bxs-text-field
                    v-model="element.peso"
                    outlined
                    dense
                    label="Peso" />
                </div>
            </li>
        </ul>

        <!-- <draggable
        v-else
        v-model="local_value"
        :disabled="local_value.length <= 1"
        ghost-class="row-ghost"
        group="cassa"
        item-key="id"
        :animation="500"
        tag="ul">
            <template #item="{ element, index }">
                <li :class="['mb-ui', {
                    'dragging': local_value.length > 1
                }]">
                    <div class="flex nowrap align-center mb-1">
                        <div
                        v-if="local_value.length > 1"
                        class="mr-1">
                            <bxs-icon name="grip-vertical" />
                        </div>

                        <small>Riga {{ index + 1 }}</small>

                        <bxs-spacer></bxs-spacer>

                        <a
                        role="button"
                        class="text-error small"
                        @click="remove(index)">Rimuovi</a>
                    </div>

                    <div>
                        <bxs-file-uploader
                        v-model="element.img"
                        class="mb-2" />

                        <bxs-text-field
                        v-model="element.titolo"
                        outlined
                        dense
                        label="Titolo"
                        class="mb-2" />

                        <bxs-text-field
                        v-model="element.numero_riferimento"
                        outlined
                        dense
                        label="Codice"
                        class="mb-2" />

                        <bxs-text-field
                        v-model="element.peso"
                        outlined
                        dense
                        label="Peso" />
                    </div>
                </li>
            </template>
        </draggable> -->
    </div>
</template>

<script>
// import draggable from 'vuedraggable'

export default {
    name: 'work_cassa_field',
    // components: {
    //     draggable
    // },
    props: {
        modelValue: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    emits: [
        'update:modelValue'
    ],
    data () {
        return {
            local_value: this.modelValue
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    watch: {
        value (newVal) {
            this.local_value = newVal
        },
        local_value (newVal) {
            this.$emit('update:modelValue', newVal)
        }
    },
    methods: {
        start () {
            // if (!this.value.length) {
            //     this.add()
            // }
        },
        add () {
            this.local_value.push({
                titolo: null,
                numero_riferimento: null,
                peso: null,
                img: null
            })
        },
        remove (i) {
            this.local_value.splice(i, 1)
        }
    }
}
</script>