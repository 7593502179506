<template>
    <div
    v-sticky="sticky"
    class="bxs-top-navigation">
        <bxs-toolbar color="background">
            <slot
            v-if="!hideUndo"
            name="undo">
                <bxs-btn
                icon
                @click="undo ? $router.push(undo) : $router.go(-1)">
                    <bxs-icon name="chevron-left" />
                </bxs-btn>
            </slot>

            <div class="bxs-top-navigation--body bxs-truncate">
                <bxs-title
                :level="6"
                class="mb-0 text-400 text-uppercase">
                    <slot />
                </bxs-title>
            </div>

            <slot name="append" />
        </bxs-toolbar>

        <slot name="append-outer" />
    </div>
</template>

<script>
export default {
    name: 'bxs-top-navigation',
    props: {
        undo: {
            type: String,
            required: false,
            default: null
        },
        'hide-undo': {
            type: Boolean,
            required: false,
            default: false
        },
        sticky: {
            type: Boolean,
            required: false,
            default: true
        }
    }
}
</script>

<style lang="scss" scoped>
.bxs-top-navigation {
    position: relative;

    > .bxs-toolbar {
        justify-content: space-between;
    }

    &--body {
        position: absolute;
        width: calc(70% - var(--size-layout));
        // max-width: var(--size-layout);
        flex: 1;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
        pointer-events: none;
    }
}
</style>