import moment from 'moment'

moment.locale('it')

const d = {
    toHumanDate(value, f = 'L') {
        return value && moment(value).isValid() ? moment(value).format(f) : (value || '--')
    },
    toHumanCalendar(value) {
        return value ? moment(value).calendar() : (value || '--')
    },
    isMajorOfNow(value) {
        if (!value || !moment(value).isValid()) return value
        return moment(value).diff(moment()) >= 0
    },
    truncate(v, l = 115) {
        if (!v) return v
        return v.substring(0, l) + '...'
    },
    highlight(v, k) {
        if (!v || !k) return v
        return v.replace(k, '<span class="text-highlight">' + k + '</span>')
    }
}

export default d