<template>
    <div class="orders">
        <bxs-top-nav>Opere</bxs-top-nav>

        <bxs-data-lazy
        v-model="items"
        :call="() => $api.works.list({ limit: 5, spread: 'front_img', sort: { created_at: -1 } })">
            <section>
                <bxs-layout>
                    <bxs-text-field
                    v-model="keywords"
                    solo
                    clearable
                    name="keywords"
                    label="Cerca opera"
                    class="mb-0"
                    @click="$router.push('/works')">
                        <template #prepend>
                            <bxs-icon
                            name="search"
                            width="1.25em"></bxs-icon>
                        </template>
                    </bxs-text-field>

                    <!-- <div class="flex">
                        <bxs-menu close-on-click>
                            <template #activator="{ on }">
                                <bxs-btn
                                v-on="on"
                                prepend-icon="filters"
                                text>Filtri</bxs-btn>
                            </template>

                            <bxs-list>
                                <bxs-list-item color="secondary">Stato opere</bxs-list-item>

                                <bxs-list-item
                                @click="selected_status = null"
                                divider>Tutte</bxs-list-item>

                                <bxs-list-item
                                v-for="(st, i) in [1, 2, 4, 4]"
                                :key="i"
                                :append-icon="selected_status === st ? 'check' : null"
                                :text-color="selected_status === st ? 'info' : null"
                                divider
                                @click="selected_status = st.value">{{ st }}</bxs-list-item>
                            </bxs-list>
                        </bxs-menu>
                    </div> -->
                </bxs-layout>
            </section>

            <!--  -->
            <section>
                <bxs-layout>
                    <ul v-if="items.docs.length > 0">
                        <li
                        v-for="order in items.docs"
                        :key="order.id"
                        class="mb-2 mb-0-last">
                            <work-row :item="order" />
                        </li>
                    </ul>

                    <div
                    v-else-if="!items.docs.length && !!keywords"
                    class="text-center">
                        <p class="text-500 mb-1">Nessun opera trovata <span v-if="!!keywords">per "{{ keywords }}"</span></p>
                        <small class="text-mute">Prova a effettuare nuovamente la ricerca.</small>
                    </div>

                    <div
                    v-else
                    class="text-center mt-ui py-ui">
                        <div class="mb-6">
                            <bxs-icon
                            name="file-earmark-text-fill"
                            width="3rem"
                            height="3rem"></bxs-icon>
                        </div>

                        <p>Nessun opera da visualizzare al momento</p>

                        <bxs-btn
                        append-icon="plus"
                        to="/works/new/edit"
                        class="mt-ui">Aggiungi opera</bxs-btn>
                    </div>
                </bxs-layout>
            </section>
        </bxs-data-lazy>
    </div>
</template>

<script>
export default {
    name: 'works',
    data () {
        return {
            busy: false,
            loading: false,
            items: {
                docs: [],
                pagination: {
                    page: 1
                }
            },
            keywords: null
        }
    },
    mounted () {
        this.$nextTick(this.play)
    },
    watch: {
        keywords () {
            this.getDocs()
        }
    },
    methods: {
        play () {
            window.addEventListener('scroll', this.handleScroll)
        },
        stop () {
            window.removeEventListener('scroll', this.handleScroll)
        },
        handleScroll () {
            if ((window.innerHeight + window.scrollY) >= (document.body.scrollHeight - (window.innerHeight / 2))) {
                if (this.items.pagination.has_next_page && !this.busy) {
                    this.getDocs(this.items.pagination.page += 1)
                }
            }
        },
        async getDocs (page = 1) {
            this.busy = true
            this.loading = true
            const filters = {}

            if (this.keywords) {
                filters.keywords = this.keywords
            }

            try {
                const {
                    docs,
                    pagination
                } = await this.$api.works.list({
                    limit: 10,
                    page: page,
                    spread: 'front_img',
                    sort: { title: 1 },
                    filters: filters
                })

                if (page === 1) this.items.docs = docs
                else this.items.docs.push(...docs)

                this.items.pagination = pagination
            } catch (err) {
            } finally {
                this.busy = false
                this.loading = false
            }
        }
    },
    beforeUnmount () {
        this.stop()
    }
}
</script>