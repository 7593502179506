<template>
    <div class="password-recovery-view">
        <bxs-toolbar
        sticky
        tile
        flat>
            <bxs-btn
            icon
            @click="$router.go(-1)">
                <bxs-icon name="chevron-left"></bxs-icon>
            </bxs-btn>

            <bxs-spacer></bxs-spacer>

            <div class="bxs-truncate text-center mx-ui">
                <h4>Recupero password</h4>
            </div>
        </bxs-toolbar>

        <section>
            <bxs-layout class="text-center">
                <div class="mb-ui">
                    <div v-if="!sucess">
                        <h5>Qual è il tuo indirizzo email?</h5>
                    </div>

                    <div v-else>
                        <h1 class="text-uppercase">Email invitata</h1>
                        <p>Abbiamo inviato un'email a <b>{{ email }}</b> Se questa email è collegata all'account Leila, potrai reimpostare la password.</p>
                    </div>
                </div>

                <div class="mb-ui">
                    <bxs-form
                    v-if="!sucess"
                    ref="form"
                    @submit="submit">
                        <bxs-text-field
                        v-model="email"
                        name="email"
                        required
                        label="Email"
                        :rules="['email']"
                        class="mb-layout" />

                        <bxs-btn
                        block
                        :disabled="loading"
                        :loading="loading"
                        @click="$refs.form.submit()">Invia</bxs-btn>
                    </bxs-form>

                    <div v-else>
                        <p>Non hai ricevuto l'email? Prova questi suggerimenti del nostro Centro assistenza.</p>
                        <bxs-btn @click="restart">Riprova</bxs-btn>
                    </div>
                </div>

                <!-- undo -->
                <div>
                    <bxs-btn
                    text
                    to="/signin">Hai già un account? Accedi</bxs-btn>
                </div>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'password_recovery',
    data () {
        return {
            email: null,
            sucess: false,
            loading: false
        }
    },
    methods: {
        async submit () {
            this.loading = true

            try {
                await this.$http.post('v1/auths/password_recovery', {
                    email: this.email
                })
                this.$router.push('/password/reset')
            } catch (err) {
                throw new Error(err.message || err.raw)
            } finally {
                this.loading = false
            }
        },
        restart () {
            this.email = null
            this.sucess = false
        }
    }
}
</script>