<template>
    <div
    class="flex nowrap align-start bxs-pointer"
    @click="$router.push(`/works/${item.id}`)">
        <div>
            <bxs-figure
            ratio="1-1"
            width="60"
            height="60"
            class="mr-ui"
            :src="item.front_img ? item.front_img.url : null" />
        </div>

        <div class="flex-1 text-truncate">
            <div class="flex nowrap align-center mb-1">
                <small class="mr-2 text-mute">{{ $filters.toHumanDate(item.updated_at) }}</small>
                <small class="mr-ui text-uppercase text-400">{{ item.numero }}</small>
            </div>

            <div class="flex nowrap justify-between align-center">
                <div class="text-truncate pr-6">
                    <p>{{ item.title }}</p>
                </div>

                <div>
                    <bxs-icon name="chevron-right"></bxs-icon>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'order_row',
    props: {
        item: {
            type: Object,
            required: true
        }
    }
}
</script>